import store from "@/store";

export default function translation(key: string) {
  try {
    const result = store?.state?.languageData?.[key] ?? key;
    if (result.toLowerCase().includes("solx")) {
      store.commit("setAdjustSolxColorTimeout");
    }
    return result;
  } catch (err) {
    console.error(err);
    return key;
  }
}
