import store from "@/store";

export function isElementInViewport(el) {
  const rect = el?.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

export function scrollToElementById(id: string) {
  try {
    const el = document.getElementById(id);
    if (!el) return;
    scrollToElementIfNeeded(el, "start", true);
    store.commit("setAppHash", id);
  } catch (err) {
    console.log("🚀 ~ scrollToElementById ~ err:", err);
  }
}

export default function scrollToElementIfNeeded(
  el?: any,
  position?: string,
  scrollIgnore?: boolean
) {
  if (!el) return;
  if (!isElementInViewport(el) || scrollIgnore) {
    el.scrollIntoView({
      behavior: "smooth",
      block: position ?? "center",
      inline: "nearest",
    });
  }
}
