<template>
  <div
    style="z-index: 99999999998"
    :style="{
      // backgroundColor: isDark() ? '#1E1E1E' : '#fff',
    }"
    class="fixed left-0 right-0 h-[100dvh] text-white overflow-y-scroll"
  >
    <div
      class="w-[calc(100vw-32px)] !max-w-[1200px] mx-auto flex flex-col items-center gap-6 justify-center !py-12 min-h-full"
    >
      <img
        :src="img || getIcon('system_maintenance')"
        alt=""
        class="block w-[calc(100vw-32px)] !max-w-[600px]"
      />
      <div class="mt_content_g lg:!text-[18px] text-base">
        <h1 class="mt_title lg:!text-[24px]">
          Scheduled Maintenance In Progress
        </h1>
        <div class="mt_des">
          Our platform is currently undergoing scheduled maintenance to bring
          you exciting updates and improvements. During this time, all services
          are temporarily unavailable.
        </div>
        <br />
        <div class="flex flex-col items-center">
          <ul>
            <li class="mt_feature_title mb-3">
              Estimated Completion Time:
              <strong>at 05 AM UTC on September 25th</strong>
            </li>
            <!-- <li
              v-for="(e, i) in store.state.appSetting?.system_enable_maintenance
                ?.features"
              :key="i"
            >
              {{ e }}
            </li> -->
            <!-- <li>Upgraded Interface: Enhanced user experience.</li>
            <li>
              New Wallet Support: Seamless transactions with Bitget, OKX and
              more.
            </li>
            <li>
              Performance and Security: Improved performance and heightened
              security features.
            </li> -->
          </ul>
        </div>
        <br />
        <div class="mt_foo_mes">
          We appreciate your patience and understanding. Please check back soon!
        </div>
      </div>
      <div class="mt_action">
        <PrimaryButton @click="reload">Reload Page</PrimaryButton>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import getIcon from "@/common/function/getIcon";
import translation from "@/common/function/translation";
import PrimaryButton from "./PrimaryButton.vue";
import isDark from "@/common/function/isDark";
import { useStore } from "vuex";
const img = process.env.VUE_APP_MT_IMG;
const reload = () => {
  window.location.reload();
};
const store = useStore();
</script>

<style scoped>
.dark .mt_content_g {
  color: var(--text-color-dark-mode-des) !important;
}
.mt_content_g {
  color: #000;
}
.dark .mt_title {
  color: var(--text-color-dark-mode);
}
.mt_title {
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 36px */
  text-transform: capitalize;
}
li {
  list-style: none;
}
.mt_des,
li {
  text-align: center;
  font-style: normal;
  font-weight: 400;
  line-height: 180%; /* 32.4px */
}
.mt_feature_title {
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
}
.mt_foo_mes {
  text-align: center;
  font-style: normal;
  font-weight: 400;
  line-height: 180%; /* 32.4px */
}
.mt_action {
}
</style>
