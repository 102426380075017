<template>
  <div
    class="home_history_container my-9 lg:my-12"
    v-if="dataTrans_v2 && dataTrans_v2['all'] && dataTrans_v2['all'].length > 0"
  >
    <section-title
      :title="translation('his_History')"
      :down-line="useResponsive().isMobileScreen"
    >
    </section-title>
    <div class="flex items-center justify-between lg:mb-9 mb-6 gap-1">
      <FilterDatePicker
        :hide-seven-day="store.state.numOfDayHasActivity <= 1"
        :hide-thirty-day="store.state.numOfDayHasActivity <= 7"
        v-if="activeTab && dataTrans_v2"
        is-hidden-option
        v-model:show="showFilter"
        :on-apply="handleFilterStatusChanged"
        prefix-key="his"
        hidden-date-description
        hide-one-day
        use-calendar-icon
      />

      <v-menu v-model="showTabFilter" v-if="activeTab && dataTrans_v2">
        <template v-slot:activator="{ props }">
          <div
            v-bind="props"
            class="cursor-pointer flex gap-5 justify-between items-center py-1 pr-2 pl-4 text-base text-black text_dark border_2 border-solid rounded-[100px] h-[45px]"
          >
            <div class="leading-[100%] pt-[2px] whitespace-nowrap">
              {{ translation(createKey(activeTab, "his")) }}
            </div>
            <img
              :src="getIcon(isDark() ? 'arrow_bottom_white' : 'arrow_bottom')"
              alt=""
              class="action_icon duration-200 icon_w24_h24"
              :style="{
                transform: `rotate(${showTabFilter ? 0 : 180}deg)`,
              }"
            />
          </div>
        </template>
        <v-list class="list_board">
          <v-list-item
            class="list_item"
            @click="() => switchTab(2, item)"
            v-for="(item, index) in Object.keys(dataTrans_v2 ?? {})"
            :key="index"
            :value="index"
            :class="{ selected_list_item_f: activeTab == item }"
          >
            <!-- {{ translation(createKey(item, "his")) }} -->
            <ListItem :is-selected="activeTab == item">{{
              translation(createKey(item, "his"))
            }}</ListItem>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <!-- <FormatterNumber :value="'0.0000000147658465483'" unit="SOL" />
    <FormatterNumber :value="'0.00000000000000186548743'" unit="SOL" />
    <FormatterNumber :value="'0.0000001543656'" unit="SOL" />
    <FormatterNumber :value="'0.0000000011'" unit="SOL" />
    <FormatterNumber :value="'73464.147'" />
    <FormatterNumber :value="'7373.147658465483'" />
    <FormatterNumber :value="0.00000001" />
    <FormatterNumber :value="0" />
    <div class="text-red">
      {{ 10 ** (-(store.state.dynamicConfigs.ss ?? 8) + 2) }}
    </div>
    <div class="text-blue">
      {{ formatter(0.0000000147658465483) }}
    </div> -->
    <card-container
      class="override_card_content"
      bg-color="purple-pizzazz"
      :displacement-level="0"
      :padding-bottom="24"
      v-if="dataTrans_v2"
    >
      <div
        class="history_block_container"
        v-for="(historyItem, index) in historyItems"
        :key="index"
      >
        <h1
          class="time_line"
          v-if="
            index == 0 ||
            (index != 0 &&
              !dayjs(historyItem.date_created).isSame(
                dayjs(historyItems[index - 1].date_created),
                'day'
              ))
          "
        >
          {{
            isToday(historyItem.date_created)
              ? translation("his_to_day")
              : formatDateTo(historyItem.date_created, false)
          }}
        </h1>
        <card-history
          v-if="historyItem.type != 'swap'"
          class="!border-[none]"
          :the-props="historyItem"
          :is-deposit-tab="activeTab == 'swap'"
        />
        <div
          class="border_2 p-4 rounded-[16px] bg_dark_light_more"
          v-if="historyItem.type == 'swap'"
        >
          <card-history
            :the-props="historyItem"
            :is-deposit-tab="activeTab == 'swap'"
            is-swap-item
          />
          <div class="divider my-4 !border-dashed"></div>
          <card-history
            :the-props="historyItem.same_swap"
            :is-deposit-tab="activeTab == 'swap'"
            is-swap-item
          />
        </div>
      </div>
      <div
        class="w-full aff_rank_pagination mt-2"
        v-if="filterHistoryByDates().length > numOfItemsPerPage"
        :class="{
          hidden_arrows:
            Math.ceil(
              (filterHistoryByDates().length ?? 0) / numOfItemsPerPage
            ) <= 4,
          hidden_prev: page == 1,
          hidden_next:
            page ==
            Math.ceil((filterHistoryByDates().length ?? 0) / numOfItemsPerPage),
        }"
      >
        <v-pagination
          v-model="page"
          :length="
            Math.ceil((filterHistoryByDates().length ?? 0) / numOfItemsPerPage)
          "
          rounded="circle"
          :total-visible="useResponsive(3, 8).value"
          color="grey-lighten-1"
          active-color="black"
        ></v-pagination>
      </div>
      <empty-card
        v-if="
          dataTrans_v2[activeTab] &&
          dataTrans_v2[activeTab].length != 0 &&
          filterHistoryByDates().length === 0
        "
        :title="
          translation(createKey(activeTab, 'his_empty_title_in_this_time'))
        "
        :description="
          translation(createKey(activeTab, 'his_empty_des_in_this_time'))
        "
        :button-content="translation('his_empty_let_change_filter')"
        hidden-button
      />
      <empty-card
        v-if="dataTrans_v2[activeTab] && dataTrans_v2[activeTab].length == 0"
        :title="translation(createKey(activeTab, 'his_empty_title'))"
        :description="translation(createKey(activeTab, 'his_empty_des'))"
        :button-content="translation(createKey(activeTab, 'his_empty_button'))"
        :hidden-button="activeTab.toLowerCase() == 'all'"
        :on-click-button="routeTarget"
      />
    </card-container>
    <div v-if="!dataTrans_v2" class="loading_wrapper">
      <LoadingWithDescription :description="translation('his_Loading___')" />
    </div>
  </div>
</template>

<script setup lang="ts">
import getIcon from "@/common/function/getIcon";
import createKey from "@/common/function/createKey";
import CardContainer from "./CardContainer.vue";
import SectionTitle from "./SectionTitle.vue";
import RoundButton from "./RoundButton.vue";
import CardHistory from "./CardHistory.vue";
import EmptyCard from "./EmptyCard.vue";
import {
  onMounted,
  ref,
  watch,
  getCurrentInstance,
  onUnmounted,
  computed,
} from "vue";
import {
  UseQueryReturn,
  provideApolloClient,
  useQuery,
} from "@vue/apollo-composable";
import {
  getHistoryTrans,
  getHistoryAffiliate,
} from "@/graphql/query/historyQuery";
import { userClient } from "@/graphql/client/user-client";
import { useStore } from "vuex";
const store = useStore();
import {
  ITransactionHistory,
  IAffiliateHistory,
  TRANSACTION_TYPE,
} from "@/common/type";
import { Emitter } from "mitt";
import useTextMessage from "@/hook/useTextMessage";
import { useMessage } from "naive-ui";
import { SUBSCRIPTION_TYPE } from "@/common/const";
import translation from "@/common/function/translation";
import remoteImage from "@/common/function/remoteImage";
import useToastMessage from "@/hook/useToastMessage";
import FilterDatePicker, { Dates } from "./FilterDatePicker.vue";
import { truncate } from "fs";
import FormatterNumber from "./FormatterNumber.vue";
import formatDateTo from "@/common/function/formatDate";
import isToday from "@/common/function/isToday";
import deleteMeaninglessZeros from "@/common/function/deleteMeaninglessZeros";
import getStandardNumber from "@/common/function/getStandardNumber";
import LoadingWithDescription from "./LoadingWithDescription.vue";
import dayjs from "dayjs";
import ListItem from "./ListItem.vue";
import isDark from "@/common/function/isDark";
import scrollToElementIfNeeded, {
  scrollToElementById,
} from "@/common/function/scrollToElementIfNeeded";
import useResponsive from "@/hook/useResponsive";
import formatter from "@/common/function/formatter";

const message = useToastMessage();
const logoContainer = ref<HTMLElement | undefined>();
const emitter: Emitter<any> =
  getCurrentInstance()?.appContext.app.config.globalProperties.emitter;
const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
const historyTrans = provideApolloClient(userClient)(() =>
  useQuery(getHistoryTrans, { timezone: timezone })
);

const showDatePickerFilter = ref<boolean>(false);

const page = ref(1);
const numOfItemsPerPage = 5;
const historyItems = ref<any>([]);

const dates = ref<Dates>([]);
const currentDate = new Date().setHours(0, 0, 0, 0);
const isTheFirst = ref<boolean>(true);
const showTabFilter = ref<boolean>(false);
const dataTrans = ref<ITransactionHistory[]>([]);
const dataTrans_v2 = ref<any>(undefined);
const activeTab = ref<string>("");
const dataAffiliate = ref<IAffiliateHistory[]>([]);
const dataToday = ref<any>([]);
const dataBefore = ref<ITransactionHistory[] | IAffiliateHistory[]>([]);
const pageSize = 10;
const showLoadMoreToday = ref(false);
const showLoadMoreBefore = ref(false);
const tabIndex = ref(0);
const pagToday = ref(pageSize);
const pagBefore = ref(pageSize);
const showFilter = ref<boolean>(false);

// const loadMoreLimit = 5;
const limitLoadMoreArray = ref<any>(undefined);

const fetchData = () => {
  historyTrans.refetch();
  historyTrans.onResult(() => {
    try {
      let result = {};
      historyTrans.result.value?.transactionHistory?.forEach((e: any) => {
        result = { ...result, ...e };
      });
      console.error(
        ">>>>>>>>>>>>>>historyTrans.result.value.transactionHistory: ",
        historyTrans.result.value.transactionHistory
      );
      dataTrans_v2.value = { ...result };
      let tempLimit = {};
      Object.keys(dataTrans_v2.value).forEach((e) => {
        tempLimit = {
          ...tempLimit,
          [e]: tempLimit[e] ?? numOfItemsPerPage,
        };
      });
      limitLoadMoreArray.value = tempLimit;
      if (
        dataTrans_v2.value &&
        Object.keys(dataTrans_v2.value)?.length > 0 &&
        isTheFirst.value
      ) {
        isTheFirst.value = false;
        activeTab.value = Object.keys(dataTrans_v2.value)[0];
      }
      const histories = dataTrans_v2.value["all"] ?? [];
      if (histories.length <= 0) {
        store.commit("setNumOfDayHasActivity", 0);
        return;
      }
      const firstHistory = histories[histories.length - 1];

      const dateTime = new Date(firstHistory?.date_created).getTime();
      const currentTime = new Date().getTime();

      const days = (currentTime - dateTime) / 86400000;
      console.log(
        "🚀 ~ historyTrans.onResult ~ currentTime:",
        firstHistory?.date_created,
        currentTime,
        dateTime,
        86400
      );
      store.commit("setNumOfDayHasActivity", days);
    } catch (error) {
      console.log("error", error);
    }
  });
};

function setDataTabActive(type: string[] | null, data: any[]) {
  for (const trans of data) {
    if (type?.includes(trans?.type ?? "") || type == null) {
      const today = new Date(trans.date_created).getTime() > currentDate;
      if (today) {
        if (dataToday.value.length <= pagToday.value) {
          if (dataToday.value.length === pagToday.value) {
            showLoadMoreToday.value = true;
            break;
          }
          if (
            (tabIndex.value == 0 &&
              trans.method == "subtract" &&
              trans.type == TRANSACTION_TYPE.LOAN) ||
            (tabIndex.value == 1 &&
              trans.method == "add" &&
              trans.type == TRANSACTION_TYPE.LOAN)
          ) {
            //
          } else {
            dataToday.value.push(trans as any);
          }
        }
      } else {
        if (dataBefore.value.length <= pagBefore.value) {
          if (dataBefore.value.length === pagBefore.value) {
            showLoadMoreBefore.value = true;
            break;
          }
          console.log("dataBefore", trans);
          dataBefore.value.push(trans as any);
        }
      }
    }
  }
}
function handleLoadMore() {
  limitLoadMoreArray.value = {
    ...limitLoadMoreArray.value,
    [activeTab.value]: page.value * numOfItemsPerPage,
  };
}

watch(activeTab, () => {
  page.value = 1;
});
// watch(dataTrans_v2, () => {
//   console.log();
//   if (

//   ) {
//     alert("true");
//     store.commit("setShowHistory", true);
//   } else {
//     alert("false");
//     store.commit("setShowHistory", false);
//   }
// });

watch(page, () => {
  handleLoadMore();
});

watch(dates, () => {
  page.value = 1;
});

watch([dataTrans_v2, activeTab, page, dates], () => {
  historyItems.value = filterHistoryByDates().slice(
    (page.value - 1) * numOfItemsPerPage,
    (page.value - 1) * numOfItemsPerPage + numOfItemsPerPage
  );
});

const filterHistoryByDates = () => {
  if (!dataTrans_v2.value || !dataTrans_v2.value[activeTab.value]) return [];
  const histories = [];
  const temp = dataTrans_v2.value[activeTab.value];
  const length = temp?.length;
  for (let i = 0; i < length; i++) {
    if (temp[i].type != "swap") {
      histories.push(temp[i]);
      continue;
    }

    const sameSwap = temp.find(
      (e, index) => e.transaction_code == temp[i].transaction_code && index > i
    );

    if (!sameSwap) continue;

    const item = {
      ...temp[i],
      same_swap: sameSwap,
    };
    histories.push(item);
  }
  return histories.filter((e) => {
    return (
      dates.value.length == 0 ||
      ((dayjs(e.date_created).isAfter(dates.value[0], "day") ||
        dayjs(e.date_created).isSame(dates.value[0], "day")) &&
        (dayjs(e.date_created).isBefore(
          dates.value[dates.value.length - 1],
          "day"
        ) ||
          dayjs(e.date_created).isSame(
            dates.value[dates.value.length - 1],
            "day"
          )))
    );
  });
};

function switchTab(index: number, activeTabName: string, reset = true) {
  activeTab.value = activeTabName;
  tabIndex.value = index;
  dataToday.value = [];
  dataBefore.value = [];
  showLoadMoreToday.value = false;
  showLoadMoreBefore.value = false;
  if (reset) {
    pagBefore.value = pageSize;
    pagToday.value = pageSize;
  }
  switch (index) {
    case 0: {
      // type stake
      console.log("Staking History");
      setDataTabActive(
        [TRANSACTION_TYPE.STAKE, TRANSACTION_TYPE.LOAN],
        dataTrans.value
      );
      break;
    }
    case 1:
      console.log("Receiving History");
      setDataTabActive(
        [
          TRANSACTION_TYPE.WITHDRAW_BONUS,
          TRANSACTION_TYPE.WITHDRAW,
          TRANSACTION_TYPE.LOAN,
          TRANSACTION_TYPE.CLAIM_STAKING_INTEREST,
          TRANSACTION_TYPE.CLAIM_STAKING_PRINCIPLE,
          TRANSACTION_TYPE.WITHDRAW_BONUS_STAKE,
        ],
        dataTrans.value
      );
      break;
    case 2:
      console.log("Swap History");
      setDataTabActive([TRANSACTION_TYPE.SWAP], dataTrans.value);
      break;
    case 3:
      console.log("Referral History", dataAffiliate.value);
      setDataTabActive(null, dataAffiliate.value);
      break;
  }
  tabIndex.value = index;
}

function routeTarget(target?: string) {
  scrollToElementById(
    activeTab.value.includes("referral") || activeTab.value.includes("reward")
      ? "link"
      : "statistics"
  );
  if (activeTab.value.includes("mint")) store.commit("setPowering", "powering");
  else if (activeTab.value.includes("boost"))
    store.commit("setPowering", "loan");
  else if (activeTab.value.includes("unwrap"))
    store.commit("setPowering", "sell");
}

const handleFilterStatusChanged = (
  datesValue: Dates,
  oldDates: Dates,
  onDone?: (data?: any) => any
) => {
  dates.value = datesValue;
  onDone && onDone();
};

watch(
  () => store.state.user,
  (newVal) => {
    if (newVal?.token) {
      fetchData();
      // showSession.value = true;
      console.log("🚀 ~ fetchData: history start");
    }
  },
  { immediate: true }
);
onMounted(() => {
  emitter.on("haveTransaction", (data) => {
    fetchData();
    if (
      data[SUBSCRIPTION_TYPE.STAKE] ||
      data[SUBSCRIPTION_TYPE.SWAP] ||
      data[SUBSCRIPTION_TYPE.CLAIM] ||
      data[SUBSCRIPTION_TYPE.CLAIM_INTEREST] ||
      data[SUBSCRIPTION_TYPE.CLAIM_PRINCIPLE] ||
      data[SUBSCRIPTION_TYPE.LOAN] ||
      data[SUBSCRIPTION_TYPE.CLAIM_SOLX_INIT] ||
      data[SUBSCRIPTION_TYPE.INVESTMENT] ||
      data[SUBSCRIPTION_TYPE.CLAIM_INVESTMENT_INTEREST] ||
      data[SUBSCRIPTION_TYPE.CLAIM_INVESTMENT_PRINCIPLE]
    ) {
      const { ...trans }: any = data[Object.keys(data)[0]];

      store.commit("setPlacement", "top-right");
      if (trans?.status === "processing") {
        message.loading(
          translation(
            "_Transaction_confirmed_please_wait_a_moment" + "_" + trans?.type
          )
        );
        setTimeout(() => {
          message.warning(
            translation(
              "_Transaction_processing_please_wait_a_moment" + "_" + trans?.type
            )
          );
        }, 1000);
      }
      if (trans?.status === "success") {
        setTimeout(() => {
          message.success(
            translation(
              "_Transaction_success_you_can_check_at_history" +
                "_" +
                trans?.type
            )
          );
        }, 3000);
      }
      if (trans?.status === "failed") {
        console.log("trans.transaction", trans.transaction);
        setTimeout(() => {
          message.error(
            translation(
              "_Transaction_failed_please_try_again" + "_" + trans?.type
            )
          );
        }, 3000);
      }
    }
  });
});
onUnmounted(() => {
  emitter.off("haveTransaction");
});
</script>

<style scoped>
.dark .time_line {
  color: var(--text-color-dark-mode);
}
.time_line {
  color: var(--Neutral-01, #141416);
  font-family: "GT Walsheim";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 110% */
  width: 100%;
  padding: 16px 0 0;
}
.sort {
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}
.override_card_content {
  margin-bottom: 8px;
  display: flex;
  padding: 36px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}
.dark .override_card_content {
  background-color: var(--dark-black-light);
  border: 2px var(--limit-color-dark-mode) solid;
}
.home_history_container {
  display: flex;
  flex-direction: column;
  /* gap: 36px; */
}
.partner_logo {
  width: 133px;
  height: 65px;
  display: block;
}
.translate_container {
  position: relative;
  height: 65px;
  overflow: hidden;
}
.logo_container {
  display: flex;
  align-items: center;
  width: 350%;
  animation: marquee 20s linear infinite;
}
.haft_part_logo {
  display: flex;
  align-items: center;
  width: 50%;
  justify-content: space-around;
}

@keyframes marquee {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}

.dark .load_more_text {
  color: var(--text-color-dark-mode);
}
.load_more {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--app-questn-com-black, #000);
  font-family: "GT Walsheim";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 180%; /* 28.8px */
  text-transform: capitalize;
  cursor: pointer;
}

.arrow {
  width: 24px;
  height: 24px;
  rotate: 180deg;
}
@media (max-width: 1280px) {
  .sort {
    flex-wrap: wrap;
    gap: 8px;
  }
  .sort_button {
    min-width: fit-content;
    max-width: 100px !important;
  }
  .time_line {
    font-size: 18px;
  }
  .override_card_content {
    padding: 14px 16px;
  }
  .logo_container {
    width: 500%;
  }
  .partner_logo {
    width: 73.8px;
    height: 36px;
    flex-shrink: 0;
  }
  .translate_container {
    height: 36px;
  }
  .override_container_img {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .home_history_container {
    /* gap: 24px; */
  }
}
.history_block_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.load_more_text {
  color: var(--app-questn-com-black, #000);
  font-family: "GT Walsheim";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.out_of_history:hover {
  transform: translateY(-5px) !important;
}
.show_filter {
  transform: scale(1) !important;
  opacity: 1 !important;
}
</style>
