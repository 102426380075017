<template>
  <div class="window">
    <div class="container h-[100dvh] flex flex-col bg_dark">
      <div class="h-0 relative">
        <nav-bar
          class="absolute duration-200"
          :class="{
            'translate-y-0': store.state.showNavbar,
            '-translate-y-[100%]': !store.state.showNavbar,
          }"
        />
      </div>
      <div
        ref="scrollableElement"
        @scroll="handleSmartNavbarOnScroll"
        class="scroll_able_section flex-1"
      >
        <div class="app_body">
          <div class="slot_container">
            <slot></slot>
          </div>
          <div id="contract"></div>
          <footer-bar class="lg:!block !hidden" />
        </div>
      </div>
      <div class="lg:!hidden h-0 relative">
        <BottomNavbar
          class="absolute mx-4 duration-200"
          :class="{
            '-translate-y-[calc(100%)]': store.state.showNavbar,
            'translate-y-[50%]': !store.state.showNavbar,
          }"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, ref } from "vue";
import FooterBar from "@/components/FooterBar.vue";
import NavBar from "@/components/NavBar.vue";
import { MAX_WIDTH } from "@/ui_config";
import FloatMenu from "@/components/FloatMenu.vue";
import useResponsive from "@/hook/useResponsive";
import { useStore } from "vuex";
import BottomNavbar from "@/components/BottomNavbar.vue";
const windowHeight = ref(800);
const scrollableElement = ref<HTMLElement | undefined>(undefined);
const windowInnerHeight = ref(800);
const store = useStore();
const preScrollValue = ref(0);
const preScrollTime = ref(new Date().getTime());
// const showNavbar = ref(true);
onMounted(() => {
  scrollableElement.value &&
    (scrollableElement.value.scrollTop = store.state.scrollValue);
});

const handleSmartNavbarOnScroll = (e) => {
  // if (scrollTimeout.value) {
  //   clearTimeout(scrollTimeout.value);
  //   scrollTimeout.value = undefined;
  // }
  // scrollTimeout.value = setTimeout(() => {
  //   const currentValue = e.target.scrollTop;
  //   const currentTime = new Date().getTime();
  //   const speed =
  //     (currentValue - preScrollValue.value) /
  //     (currentTime - preScrollTime.value);
  //   preScrollValue.value = currentValue;
  //   preScrollTime.value = currentTime;
  //   console.log(speed)
  //   if (speed > 1) {
  //     store.commit("setShowNavbar", false);
  //     clearTimeout(scrollTimeout.value);
  //     scrollTimeout.value = undefined;
  //     return;
  //   }
  //   if (speed < -1) {
  //     store.commit("setShowNavbar", true);
  //     clearTimeout(scrollTimeout.value);
  //     scrollTimeout.value = undefined;
  //     return;
  //   }
  // }, 300);
  const currentValue = e.target.scrollTop;
  const currentTime = new Date().getTime();
  const speed =
    (currentValue - preScrollValue.value) / (currentTime - preScrollTime.value);
  preScrollValue.value = currentValue;
  preScrollTime.value = currentTime;
  if (speed > 1) {
    store.commit("setShowNavbar", false);
    return;
  }
  if (speed < -1) {
    store.commit("setShowNavbar", true);
    return;
  }
};
</script>

<style scoped>
.window {
  background-color: #fff;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
}
.container {
  position: relative;
  width: 100%;
  margin: 0 auto;
  max-width: unset;
}
.navbar {
  /* position: fixed;
  top: 0;
  left: 0;
  right: 0;*/
  z-index: 999;
  width: 100vw;
}
.dark .scroll_able_section {
  background-color: var(--dark-black);
}
@media (max-width: 1279px) {
  .scroll_able_section {
    /* height: calc(100dvh - 85px - 98px) !important; */
  }
}
.scroll_able_section {
  background-color: #fff;
  overflow-y: scroll;
  overflow-x: hidden;
  /* margin-top: v-bind(NAV_BAR_HEIGHT + "px"); */
  width: 100%;
  /* height: calc(100dvh - 85px); */
  display: flex;
}

.app_body {
  max-width: v-bind(MAX_WIDTH + "px") !important;
  width: 100%;
  margin: 0 auto;
  height: fit-content;
}
.slot_container {
}

/* width */
::-webkit-scrollbar {
  width: v-bind(0 + "px");
}

/* Track */
::-webkit-scrollbar-track {
  background: #eee;
  border-radius: 100px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #bbb;
  border-radius: 100px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #999;
}
.float_menu {
  display: none;
}
@media (max-width: 1279px) {
  .window {
  }
  .container {
  }
  .scroll_able_section {
  }
  .slot_container {
  }
  .navbar {
  }
  .float_menu {
    display: flex;
  }
  ::-webkit-scrollbar {
    width: v-bind(0 + "px");
  }
}
</style>
