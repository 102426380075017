<template>
  <div class="alliance_group_container">
    <section-title
      :title="translation('hie_Hierarchy')"
      :down-line="useResponsive().isMobileScreen"
    >
    </section-title>
    <div>
      <!-- <div
        class="text-neutral-400 text-sm font-normal font-['GT Walsheim'] leading-tight my-2"
      >
        <span class="whitespace-nowrap">{{
          formatDateTo(appliedDates[0]?.toISOString(), false)
        }}</span>
        <span v-if="appliedDates.length > 1" class="px-1">{{
          translation("date_space")
        }}</span>
        <span v-if="appliedDates.length > 1" class="whitespace-nowrap">{{
          formatDateTo(
            appliedDates[appliedDates.length - 1]?.toISOString(),
            false
          )
        }}</span>
      </div> -->
      <div class="flex items-center justify-between lg:mb-9 mb-6 gap-1">
        <FilterDatePicker
          hidden-date-description
          v-if="store.state.user?.token && store.state.currentAffTree"
          class="duration-200 overflow-hidden"
          :class="{ '!max-w-[0px]': showInput }"
          v-model:show="showFilter"
          prefix-key="aff"
          :on-apply="handleFilterStatusChanged"
          :on-change="(newDates) => (dates = newDates)"
          hide-one-day
          is-hidden-option
          use-calendar-icon
        />
        <div class="flex flex-1 justify-end">
          <div
            @click="
              () => {
                store.commit('setAffShowType', 'board');
                let data = store.state.affTreeHistory[0];
                store.commit('replaceFirstElementOffAffTreeHistory', {
                  ...data,
                  ranking: createRankObject(data.ranking),
                });
              }
            "
            v-if="store.state.affShowType == 'tree'"
            :style="{
              backgroundColor: isDark() ? '#F3BA2F' : '#EBFF00',
            }"
            class="!border-2 !border-black rounded-full flex items-center gap-1 cursor-pointer hover:translate-x-[-8px] duration-200 w-[44.8px] h-[44.8px] lg:!w-fit lg:px-2 justify-center"
          >
            <img :src="getIcon(`back_icon`)" alt="" class="action_icon" />
            <div class="back_content lg:block hidden">
              {{ translation("hie_back") }}
            </div>
          </div>
          <div
            class="border_2 rounded-full flex items-center h-[44.8px]"
            v-if="store.state.affShowType != 'tree'"
            :class="{
              'w-[44,8px]': !showInput && useResponsive().isMobileScreen,
              'w-full': showInput,
              '!bg-[#1e1e1e]': isDark(),
            }"
          >
            <input
              ref="affInput"
              type="text"
              class="hierarchy_search_input w-full lg:w-[230px] h-full duration-200"
              :placeholder="translation('hie_Search')"
              v-model="searchText"
              :class="{
                '!max-w-[0px] !p-0 h-0':
                  !showInput && useResponsive().isMobileScreen,
              }"
              @input="handleInputSearch"
            />
            <img
              @click="handleClickSearchIcon"
              :src="
                getIcon(
                  showInput
                    ? isDark()
                      ? 'close_white'
                      : 'close'
                    : isDark()
                    ? 'search_white'
                    : 'search_light'
                )
              "
              alt=""
              class="search_icon duration-200"
              :class="{
                'size-full !aspect-square': !showInput,
                '!size-3 mr-3 duration-0': showInput,
              }"
            />
            <!-- <img
              v-if="store.state.affShowType == 'tree'"
              :src="getIcon('vertical_divider')"
              alt=""
              class="lg:block hidden divider_icon"
            /> -->
          </div>
        </div>
      </div>
    </div>
    <!-- <div>{{ store.state.allianceData?.children }}</div> -->
    <div class="w-full">
      <div class="min-w-[300px]">
        <ChartTree
          v-if="store.state.allianceData && store.state.affShowType == 'tree'"
          :data="standardDataForAffTree(store.state.chartTreeData)"
        />
        <div
          v-if="store.state.allianceData == undefined"
          class="loading_wrapper"
        >
          <LoadingWithDescription
            :description="translation('hie_Loading___')"
          />
        </div>
        <div
          class="rank_board"
          v-if="store.state.allianceData && store.state.affShowType == 'board'"
        >
          <div
            class="shadow-rank"
            v-if="!store.state?.isSearching && store.state.user?.token"
          >
            <div v-if="store.state.allianceData" class="flex flex-col gap-6">
              <div class="flex items-center justify-between">
                <div class="flex items-center gap-1.5 wallet_address">
                  <img
                    v-if="
                      store.state.affTreeHistory[0].userId !=
                      store.state.allianceData?.userId
                    "
                    @click="handleClickPrevious"
                    :src="
                      getIcon(
                        isDark() ? 'previous_icon_white' : 'previous_icon'
                      )
                    "
                    alt=""
                    class="w-6 h-6 cursor-pointer"
                  />
                  <div class="">
                    {{
                      compactWalletAddress(
                        store.state.affTreeHistory[0]?.wallet_address
                      )
                    }}
                  </div>
                  <div class="rank_name whitespace-nowrap">
                    {{
                      translation(
                        createKey(
                          createKeyByNumber(
                            store.state.affTreeHistory[0]?.ranking?.rank,
                            "_r"
                          ),
                          "_rank_name"
                        )
                      )
                    }}
                  </div>
                </div>
                <div
                  v-if="
                    store.state.affTreeHistory[0].userId !=
                    store.state.allianceData?.userId
                  "
                  class="flex items-center cursor-pointer"
                  @click="handleClickYourBranch"
                >
                  <div
                    class="text-neutral-400 text-base font-medium font-['GT Walsheim'] leading-tight"
                  >
                    {{ translation("aff_your_branch") }}
                  </div>
                  <img
                    :src="getIcon('right_arrow_aff')"
                    alt=""
                    class="w-6 h-6 block"
                  />
                </div>
              </div>
              <LevelRankItem
                :is-root="true"
                :data="store.state.affTreeHistory[0]"
                :hide-header="
                  !Array.isArray(store.state.affTreeHistory[0]?.children) ||
                  store.state.affTreeHistory[0]?.children.length <= 0
                "
                :border-bottom="true"
                :style="{
                  cursor: 'default !important',
                  background:
                    store.state.affTreeHistory[0].userId ==
                    store.state.allianceData?.userId
                      ? isDark()
                        ? 'var(--Gradient, linear-gradient(90deg, rgba(41, 130, 234, 0.36) 0%, rgba(161, 103, 254, 0.36) 100%), #000)'
                        : '#60E5D3'
                      : isDark()
                      ? '#323232'
                      : '#E8FF8D',
                }"
                hidden-hover-flag
                custom-personal-key="aff_personal_root"
                custom-group-key="aff_group_root"
              />
              <div
                class="border_2 rounded-[24px] lg:p-6 p-4 bg-[#F5FFCE] bg_dark_light"
                v-if="
                  store.state.affTreeHistory[0].children &&
                  store.state.affTreeHistory[0].children.length > 0
                "
              >
                <v-tabs v-model="rankTab">
                  <div class="flex items-center gap-2">
                    <RankTabItem
                      @click="
                        () => {
                          const newRanks = new Set([
                            rank,
                            ...ranks.sort((a, b) => b - a),
                          ]);
                          ranks = Array.from(newRanks);
                          rankTab = rank;
                        }
                      "
                      v-for="rank in ranks"
                      :key="rank"
                      :rank="rank"
                      :active="rankTab == rank"
                    />
                  </div>
                </v-tabs>
                <v-tabs-window v-model="rankTab">
                  <v-tabs-window-item
                    :value="rank"
                    v-for="rank in ranks"
                    :key="rank"
                  >
                    <!-- <AffiliateItem :data="store.state.affTreeHistory[0]" /> -->
                    <!-- {{
                      `${filterByRank(
                        store.state.affTreeHistory[0]?.children
                      ).find((e) => e[0]?.ranking?.rank == rank)}`
                    }} -->
                    <div
                      class="flex flex-col gap-2 mt-6"
                      v-if="
                        filterByRank(
                          store.state.affTreeHistory[0]?.children
                        ).find((e) => e[0]?.ranking?.rank == rank)?.length > 0
                      "
                    >
                      <LevelRankItem
                        v-for="(e, i) in filterByRank(
                          store.state.affTreeHistory[0]?.children
                        )
                          .find((e) => e[0]?.ranking?.rank == rank)
                          ?.slice(
                            (page - 1) * numOfItemsPerPage,
                            (page - 1) * numOfItemsPerPage + numOfItemsPerPage
                          )"
                        @click.stop="() => handleClickLevel(e)"
                        :key="rank + i"
                        :data="e"
                        :id="e?.userId"
                      />
                    </div>

                    <EmptyCard
                      class="mt-6 !bg-transparent"
                      v-if="
                        !filterByRank(
                          store.state.affTreeHistory[0]?.children
                        ).find((e) => e[0]?.ranking?.rank == rank) ||
                        filterByRank(
                          store.state.affTreeHistory[0]?.children
                        ).find((e) => e[0]?.ranking?.rank == rank)?.length <= 0
                      "
                      :title="
                        translation(
                          'hie_Your_multi_level_system_does_not_have_data_yet_' +
                            rank
                        )
                      "
                      :description="
                        translation(
                          'hie_Please_do_share_the_link__so_your_friends_can_help_you_with_this_' +
                            rank
                        )
                      "
                      :icon="getIcon('alliance_share')"
                      :button-content="
                        translation('hie_Share_referral_link_now')
                      "
                      :on-click-button="shareReferralLink"
                    />

                    <div
                      class="w-full aff_rank_pagination mt-6"
                      :class="{
                        hidden_arrows:
                          Math.ceil(
                            (filterByRank(
                              store.state.affTreeHistory[0]?.children
                            )?.find((e) => e[0]?.ranking?.rank == rank)
                              ?.length ?? 0) / numOfItemsPerPage
                          ) <= 4,
                        hidden_prev: page == 1,
                        hidden_next:
                          page ==
                          Math.ceil(
                            (filterByRank(
                              store.state.affTreeHistory[0]?.children
                            )?.find((e) => e[0]?.ranking?.rank == rank)
                              ?.length ?? 0) / numOfItemsPerPage
                          ),
                      }"
                      v-if="
                        filterByRank(
                          store.state.affTreeHistory[0]?.children
                        )?.find((e) => e[0]?.ranking?.rank == rank)?.length >
                        numOfItemsPerPage
                      "
                    >
                      <v-pagination
                        v-model="page"
                        :total-visible="useResponsive(5, 10).value"
                        :length="
                          Math.ceil(
                            (filterByRank(
                              store.state.affTreeHistory[0]?.children
                            )?.find((e) => e[0]?.ranking?.rank == rank)
                              ?.length ?? 0) / numOfItemsPerPage
                          )
                        "
                        rounded="circle"
                        color="grey-lighten-1"
                        active-color="black"
                      ></v-pagination>
                      <!-- <v-pagination
                        v-model="page"
                        :length="
                          Math.ceil(
                            (filterByRank(
                              store.state.affTreeHistory[0]?.children
                            )?.find((e) => e[0]?.ranking?.rank == rank)
                              ?.length ?? 0) / numOfItemsPerPage
                          )
                        "
                        rounded="circle"
                        color="grey-lighten-1"
                        active-color="black"
                      ></v-pagination> -->
                    </div>
                  </v-tabs-window-item>
                </v-tabs-window>
              </div>
              <EmptyCard
                class="border_2 rounded-[24px] lg:p-6 p-4 bg-[#F5FFCE] bg_dark_light"
                v-if="store.state.allianceData?.children?.length == 0"
                :title="
                  translation(
                    'hie_Your_multi_level_system_does_not_have_data_yet'
                  )
                "
                :description="
                  translation(
                    'hie_Please_do_share_the_link__so_your_friends_can_help_you_with_this'
                  )
                "
                :icon="getIcon('alliance_share')"
                :button-content="translation('hie_Share_referral_link_now')"
                :on-click-button="shareReferralLink"
              />
            </div>

            <!-- <div v-if="store.state.allianceData == undefined">
              <LoadingWithDescription
                :description="translation('hie_Loading___')"
              />
            </div> -->
          </div>
          <div
            v-show="store.state?.isSearching && store.state.user?.token"
            class="search_result_board"
          >
            <LevelRankItem
              v-for="(item, index) in (store.state.searchData)?.map((e: any) => ({...e,ranking: createRankObject(e.ranking)}))"
              :key="index"
              :data="item"
              :border-bottom="true"
              background-color="#FDF6EB"
              padding-left="0px"
              @click="() => handleClickSearchItem(item)"
            />
            <div v-if="!store.state.searchData" class="border_2 rounded-[24px]">
              <LoadingWithDescription
                :description="translation('hie_Searching___')"
              />
            </div>
            <div
              v-show="store.state.searchData?.length <= 0"
              class="user_not_found_group border_2 rounded-[24px]"
            >
              <span>{{ translation("hie_User_not_found") }}</span>
              <span>{{
                translation(
                  "hie_We_were_unable_to_search_for_data_about_this_user"
                )
              }}</span>
            </div>
          </div>
          <!-- <div v-if="true">
            <EmptyCard
              hidden-button
              :title="
                translation(
                  'hie_Connect_your_wallet_to_get_alliance_information'
                )
              "
              :description="
                translation(
                  'hie_Please_do_share_the_link__so_your_friends_can_help_you_with_this'
                )
              "
              :icon="getIcon('alliance_share')"
              :button-content="translation('hie_Share_referral_link_now')"
              :on-click-button="shareReferralLink"
            />
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import SectionTitle from "@/components/SectionTitle.vue";
import EmptyCard from "@/components/EmptyCard.vue";
import { DatePickerInst, NDatePicker } from "naive-ui";
import DatePickerRange from "@/components/DatePickerRange.vue";
import { onMounted, ref, watch } from "vue";
import filterByRank from "@/common/function/filterByRank";
import { assignLevelAndBackgroundColor } from "@/common/function/assignLevelAndBackgroundColor";
import { useStore } from "vuex";
import getIcon from "@/common/function/getIcon";
import standardDataForAffTree from "@/common/function/standardDataForAffTree";
import useResponsive from "@/hook/useResponsive";
import { provideApolloClient, useQuery } from "@vue/apollo-composable";
import { userClient } from "@/graphql/client/user-client";
import { getAllianceDataQuery } from "@/graphql/query/getAllianceDataQuery";
import LoadingWithDescription from "./LoadingWithDescription.vue";
import createRankObject from "@/common/function/createRankObject";
import translation from "@/common/function/translation";
import ChartTree from "./ChartTree.vue";
import AffiliateItem from "./Affiliate/AffiliateItem.vue";
import LevelRankItem from "./Affiliate/LevelRankItem.vue";
import { getUserByWalletAddress } from "@/common/function/getUserByWalletAddress";
import { getUserById } from "@/common/function/getUserById";
import setShow from "@/common/function/setShow";
import { isAncestorOf } from "@/common/function/isAncestorOf";
import fetchAllianceData from "@/common/function/fetchAllianceData";
import RoundButton from "./RoundButton.vue";
import FilterDatePicker, { Dates } from "./FilterDatePicker.vue";
import isDark from "@/common/function/isDark";
import dayjs from "dayjs";
import createKey from "@/common/function/createKey";
import createKeyByNumber from "@/common/function/createKeyByNumber";
import scrollToElementIfNeeded, {
  scrollToElementById,
} from "@/common/function/scrollToElementIfNeeded";
import compactWalletAddress from "@/common/function/compactWalletAddress";
import KatexTooltip from "./KatexTooltip.vue";
import RankTabItem from "./RankTabItem.vue";
import formatDateTo from "@/common/function/formatDate";
type DisplayType = "board" | "tree";
import DayJsAdapter from "@date-io/dayjs";
const store = useStore();
const searchText = ref("");
const searchTimeout = ref<any>(undefined);
const showFilter = ref<boolean>(false);
const showInput = ref<boolean>(false);
const dates = ref<Dates>([]);
const appliedDates = ref<Dates>([]);

const ranks = ref<number[]>([]);

const rankTab = ref(0);
const affInput = ref<HTMLElement | null>(null);
const page = ref(1);
watch(
  () => store.state.affTreeHistory[0],
  () => {
    ranks.value = filterByRank(store.state.affTreeHistory[0]?.children).map(
      (e) => e[0]?.ranking?.rank
    );
    rankTab.value = ranks.value[0];

    page.value =
      (store.state.affTreeHistory && store.state.affTreeHistory[0]?.page) ?? 1;
  },
  { immediate: true }
);

watch(rankTab, () => {
  page.value = 1;
});

const numOfItemsPerPage = ref(5);

const shareReferralLink = () => {
  scrollToElementById("link");
};

const handleClickPrevious = () => {
  searchText.value = "";
  store.commit("setIsSearching", false);
  if (store.state.affTreeHistory[0].userId != store.state.allianceData?.userId)
    store.commit("removeFirstElementOffAffTreeHistory");
};

const handleClickYourBranch = () => {
  store.commit("setAffTreeHistory", [
    {
      ...store.state.affTreeHistory[store.state.affTreeHistory?.length - 1],
      page: 1,
    },
  ]);
};

const handleClickLevel = (item?: any) => {
  if (!item.children || item.children.length <= 0) return;
  store.commit("setSearchSelected", undefined);
  // if (!item.children || item.children.length == 0) {
  //   return;
  // }

  if (!item.parent) return;
  if (item.show) {
    store.commit("removeFirstElementOffAffTreeHistory");
    store.commit("setCurrentAffTree", {
      ...item.parent,
      show: true,
      showingRank: -1,
    });
  } else {
    store.state.affTreeHistory[0] &&
      store.commit("replaceFirstElementOffAffTreeHistory", {
        ...store.state.affTreeHistory[0],
        page: page.value,
      });
    store.commit("addFirstElementOffAffTreeHistory", {
      ...item,
      show: true,
      showingRank: -1,
      // page: page.value
    });
    store.commit("setCurrentAffTree", {
      ...item,
      show: true,
      showingRank: -1,
    });
    setTimeout(() => {
      const element = document.getElementById(`${item?.userId}`);
      element && scrollToElementIfNeeded(element);
    }, 200);
  }
};

const handleClickSearchIcon = () => {
  if (!useResponsive().isMobileScreen) return;
  showInput.value = !showInput.value;
  if (showInput.value) {
    affInput.value.focus();
    return;
  } else {
    searchText.value = "";
    store.commit("setIsSearching", false);
    store.commit("setSearchSelected", undefined);
  }
};

const handleClickSearchItem = (item: any) => {
  if (!item.children || item.children.length <= 0) return;
  if (store.state.isSearching) {
    store.commit("setSearchSelected", item);
    const scrollToMemberTimeOut = setTimeout(() => {
      const element = document.getElementById(
        `${store.state.searchSelected?.userId}`
      );
      element?.scrollIntoView({ behavior: "smooth", block: "center" });

      clearTimeout(scrollToMemberTimeOut);
    }, 500);
  } else {
    store.commit("setSearchSelected", undefined);
  }

  const user: any = getUserById(
    store.state.allianceData?.children,
    item?.userId
  );

  if (user == -1) {
    store.commit("setAllianceData", undefined);
    let newDates: Dates = [];
    const adapter = new DayJsAdapter();
    const date = adapter.date(dates.value[0]);
    if (dates.value?.length == 1) {
      newDates.unshift(adapter.endOfDay(date));
      newDates.unshift(adapter.startOfDay(date));
    } else {
      newDates = dates.value;
    }
    fetchAllianceData(
      false,
      false,
      {
        startTime: newDates[0]?.toISOString(),
        keyword: "",
        endTime: newDates[newDates.length - 1]?.toISOString(),
      },
      () => {
        const user: any = getUserById(
          store.state.allianceData?.children,
          item?.userId
        );
        store.commit("addFirstElementOffAffTreeHistory", {
          ...user,
          show: true,
          parent: null,
          showingRank: -1,
          ranking: createRankObject(user.ranking),
        });
        store.commit("setIsSearching", false);
        store.commit("setSearchData", undefined);
      }
    );
    return;
  }
  store.commit("addFirstElementOffAffTreeHistory", {
    ...user,
    show: true,
    parent: null,
    showingRank: -1,
    ranking: createRankObject(user.ranking),
  });
  //

  store.commit("setIsSearching", false);
  store.commit("setSearchData", undefined);
};

const handleInputSearch = () => {
  clearTimeout(searchTimeout.value);
  searchTimeout.value = setTimeout(() => {
    handleSearch();
    clearTimeout(searchTimeout.value);
  }, 800);
};

const handleFilter = (dates: Dates, onDone?: (data: any) => any) => {
  searchText.value = undefined;
  store.commit("setIsSearching", !!searchText.value);
  store.commit("setSearchData", undefined);
  store.commit("setSearchSelected", undefined);
  let newDates: Dates = [];
  const adapter = new DayJsAdapter();
  const date = adapter.date(dates[0]);
  if (dates.length == 1) {
    newDates.unshift(adapter.endOfDay(date));
    newDates.unshift(adapter.startOfDay(date));
  } else {
    newDates = dates;
  }
  store.commit("setAllianceData", undefined);
  fetchAllianceData(
    false,
    false,
    {
      startTime: newDates[0]?.toISOString(),
      keyword: searchText.value,
      endTime: newDates[newDates.length - 1]?.toISOString(),
    },
    onDone,
    true
  );
};
const handleSearch = async () => {
  store.commit("setIsSearching", !!searchText.value);
  store.commit("setSearchData", undefined);
  store.commit("setSearchSelected", undefined);
  if (searchText.value) {
    let newDates: Dates = [];
    const adapter = new DayJsAdapter();
    const date = adapter.date(dates.value[0]);
    if (dates.value?.length == 1) {
      newDates.unshift(adapter.endOfDay(date));
      newDates.unshift(adapter.startOfDay(date));
    } else {
      newDates = dates.value;
    }

    const { result, refetch, onResult } = provideApolloClient(userClient)(() =>
      useQuery(getAllianceDataQuery, {
        requestGetAffiliateInput: {
          startTime: newDates[0],
          keyword: searchText.value,
          endTime: newDates[newDates.length - 1],
        },
      })
    );
    onResult(() => {
      if (result.value) {
        result.value = JSON.parse(JSON.stringify(result.value));
        const data = result.value?.affiliateData?.data || [];
        if (Array.isArray(data)) {
          store.commit("setSearchData", data);
        }
      }
    });
    refetch();
  } else {
    store.commit("setAllianceData", undefined);
    store.commit("setAffTreeHistory", []);
    handleFilter(dates.value);
  }
};

const handleFilterStatusChanged = (
  theDates: Dates,
  oldDates: Dates,
  onDone?: (data: any) => any
) => {
  appliedDates.value = theDates;
  handleFilter(theDates, onDone);
};
</script>

<style scoped>
.shadow-rank {
  box-shadow: 0px 4px 42px 0px rgba(34, 33, 33, 0.08);
}
.dark .rank_board {
  /* border: 2px solid var(--limit-color-dark-mode); */
  background: transparent;
}
.rank_board {
  /* border-radius: 16px; */
  /* border: 2px solid var(--app-questn-com-black, #000); */
  background: var(--app-questn-com-nero, #fff);
  width: 100%;
  overflow: hidden;
}
.search_result_board {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.alliance_group_container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
}
.hierarchy_button_container {
}
.dark .hierarchy_search_input {
  background: var(--dark-black-light-more);
  color: var(--text-color-dark-mode);
}
.hierarchy_search_input {
  padding: 12px 16px;
  background: var(--app-questn-com-nero, #fff);
  outline: none;
  border: none;
  border-radius: inherit;
  /*  */
  color: var(--Neutral-01, #141416);
  font-family: "GT Walsheim";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 22.4px */
}
.hierarchy_search_input::placeholder {
  color: var(--app-questn-com-tower-gray, #b0bec5);
  font-family: "GT Walsheim";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 22.4px */
}
.search_icon {
  display: block;
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.action_icon {
  cursor: pointer;
}
.action_container {
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
}
.divider_icon {
}
.dark .user_not_found_group {
  color: var(--text-color-dark-mode);
}
.user_not_found_group {
  color: var(--app-questn-com-black, #000);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  padding: 24px;
}
.user_not_found_group span:nth-child(1) {
  text-align: center;
  font-family: "GT Walsheim";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 27px */
  text-transform: capitalize;
}
.dark .user_not_found_group span:nth-child(2) {
  opacity: 0.7;
}
.user_not_found_group span:nth-child(2) {
  text-align: center;
  font-family: "GT Walsheim";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%; /* 32.4px */
}
@media (max-width: 1279px) {
  .divider_icon {
    margin: 0;
  }
  .user_not_found_group {
    padding: 16px;
  }
  .alliance_group_container {
    /* gap: 24px; */
  }
}
.back_content {
  color: var(--Neutral-01, #141416);
  text-align: center;
  font-family: "GT Walsheim";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 19.6px */
  letter-spacing: 0.28px;
}
.show_filter {
  transform: scale(1) !important;
  opacity: 1 !important;
}
.dark .wallet_address {
  color: var(--text-color-dark-mode);
}
.wallet_address {
  color: var(--app-questn-com-black, #000);
  font-family: "GT Walsheim";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.rank_name {
  color: #8247e5;
}
</style>
